import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { notification } from 'antd'
import { useRecoilState } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedPoint,
  selectedPointDetail
} from 'store/buildingStore'
import { displayToggleState } from 'store/displayToggleStore'
import useSWR from 'swr'
import { FloorMapElement } from 'types/building'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import useMutation from './useMutation'

export default function usePoint() {
  const { id: buildingId, floorId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [, setDisplayToggle] = useRecoilState(displayToggleState)
  const [, setPointDetail] = useRecoilState(selectedPointDetail)
  const [, setBuildingMode] = useRecoilState(buildingModeState)
  const [points, setPoints] = useRecoilState(listPointState)

  const { mutate: refetchPoints, isValidating } = useSWR<FloorMapElement>(
    {
      url: 'v1/points',
      params: {
        buildingId,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const { isMutating } = useMutation('v1/points')

  const { trigger: handleSort, isMutating: isSorting } =
    useMutation('v1/points/reorder')

  const { trigger: handleLockUnLock, isMutating: isLocking } =
    useMutation('v1/points/lock')

  const handleSortPoint = (idList: { id: string; order: number }[]) => {
    !isSorting &&
      handleSort(
        {
          data: {
            building_id: Number(buildingId),
            floor_id: Number(floorId),
            orders: idList
          }
        },
        {
          onSuccess: () => {
            setPoints((prev) =>
              prev.map((point) => ({
                ...point,
                order:
                  idList.find((item) => item.id === point.id)?.order ||
                  point.order
              }))
            )
          },
          onError: (error) => {
            Utils.handleErrorNavigate(error, navigate)
          }
        }
      )
  }

  const handleToggleLockOrUnLockPoint = (lockedById: any) => {
    notification.destroy('placingAnnotation')
    notification.destroy('placingSetAnnotation')
    !isLocking &&
      currentSelectedPoint &&
      handleLockUnLock(
        {
          data: {
            building_id: Number(buildingId),
            floor_id: Number(floorId)
          },
          query: [currentSelectedPoint]
        },
        {
          onSuccess: (data) => {
            const { isLocked, lockedBy }: { isLocked: boolean; lockedBy: any } =
              data.data
            Krpano.TogglePointLock(isLocked)
            isLocked === false &&
              setBuildingMode((prev) => ({ ...prev, selectedMode: '' }))
            setDisplayToggle({ lockedBy: isLocked ? lockedById : '', isLocked })
            setPoints((prev) =>
              prev.map((item) =>
                item.id === currentSelectedPoint
                  ? {
                      ...item,
                      lockedBy: isLocked ? lockedById : '',
                      isLocked,
                      lockedByUser: isLocked ? lockedBy : null
                    }
                  : item
              )
            )
            setPointDetail(
              (prev) =>
                prev && {
                  ...prev,
                  versionId: data.data.versionId
                    ? data.data.versionId
                    : prev.versionId,
                  lockedBy: isLocked ? lockedById : '',
                  isLocked,
                  lockedByUser: isLocked ? lockedBy : null
                }
            )
          },
          onError: (error) => {
            if (
              error.response.data.error?.key === 'point_is_locked_by_another'
            ) {
              notification.error({
                message: t('building.pointIsLockedByAnother'),
                key: error.response.data.error.key
              })
              refetchPoints().then(() =>
                setDisplayToggle({
                  lockedBy: '',
                  isLocked: true
                })
              )
            } else {
              navigate(`/buildings/${buildingId}`)
              Utils.handleErrorNavigate(error, navigate)
            }
          }
        }
      )
  }

  return {
    points,
    refetchPoints,
    isValidating,
    isMutating,
    isSorting,
    currentSelectedPoint,
    setCurrentSelectedPoint,
    isLocking,
    setPointDetail,
    handleSortPoint,
    handleToggleLockOrUnLockPoint
  }
}
