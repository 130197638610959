import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notification, Typography } from 'antd'
import clsx from 'clsx'
import { useRecoilState, useRecoilValue } from 'recoil'
import { buildingModeState, setAnnotationModeState } from 'store/buildingStore'
import Krpano from 'utils/krpano'

import SetAnnoCreateIcon from 'components/icons/SetAnnoCreateIcon'
import SetAnnoPlacingIcon from 'components/icons/SetAnnoPlacingIcon'
import SetAnnoSearchIcon from 'components/icons/SetAnnoSearchIcon'

import ModalAddSetAnnotation from './ModalAddSetAnnotation'
import ModalDeleteSetAnnotation from './ModalDeleteSetAnnotation'
import ModalListSetAnnotation from './ModalListSetAnnotation'

type Props = {
  isCheckLockedPoint: boolean
}

export default function MenuSetAnnotation({ isCheckLockedPoint }: Props) {
  const { t } = useTranslation()

  const [isExpanding, setIsExpanding] = useState(false)
  const [isSelectAnnotationPos, setIsSelectAnnotationPos] = useState(false)
  const [annotationPosition, setAnnotationPosition] = useState<string[]>([])

  const [{ mode }, setSelectedMode] = useRecoilState(setAnnotationModeState)
  const buildingMode = useRecoilValue(buildingModeState)

  const isDisablePlacing = useMemo(
    () =>
      !isCheckLockedPoint ||
      buildingMode.selectedMode ||
      (mode && mode !== 'placingSetAnnotation'),
    [buildingMode.selectedMode, isCheckLockedPoint, mode]
  )

  const isDisableCreating = useMemo(
    () =>
      buildingMode.selectedMode ||
      !isCheckLockedPoint ||
      (mode && mode !== 'addSetAnnotation'),
    [buildingMode.selectedMode, isCheckLockedPoint, mode]
  )

  const isDisableSearching = useMemo(
    () =>
      !isCheckLockedPoint ||
      buildingMode.selectedMode ||
      (mode && mode !== 'listSetAnnotation'),
    [buildingMode.selectedMode, isCheckLockedPoint, mode]
  )

  const handleSelectMode = useCallback(
    (mode: string | undefined) => {
      setSelectedMode((prev) => ({ ...prev, mode }))
    },
    [setSelectedMode]
  )

  const handleCancelPlacing = useCallback(() => {
    setIsSelectAnnotationPos(false)
    handleSelectMode(undefined)
    setAnnotationPosition([])
    notification.destroy('placingSetAnnotation')
    Krpano.RemoveTempAnnotation()
  }, [handleSelectMode])

  const handleTogglePlacingSetAnnotation = () => {
    if (isDisablePlacing) return
    if (isSelectAnnotationPos) {
      handleCancelPlacing()
      return
    }
    notification.success({
      key: 'placingSetAnnotation',
      message: t('translation.addSetAnnotationGuide'),
      duration: null
    })
    setIsSelectAnnotationPos(true)
    handleSelectMode('placingSetAnnotation')
  }

  const handleToggleCreatingSetAnnotation = () => {
    if (isDisableCreating) return
    handleSelectMode('addSetAnnotation')
  }

  const handleToggleModalListSetAnnotation = () => {
    if (isDisableSearching) return
    handleSelectMode('listSetAnnotation')
  }

  // handle event of krpano
  const getSetAnnotationPosition = useCallback(() => {
    ;(window as any).get_set_annotation_position = () => {
      if (isSelectAnnotationPos && isCheckLockedPoint) {
        const krpano = document.getElementById('embedpano-full') as any
        const h = krpano.get('mouseath')
        const v = krpano.get('mouseatv')
        setAnnotationPosition((prev: any) => [...prev, h, v])
        if (!annotationPosition?.length) {
          krpano.call(
            `set(hotspot[temp_circle_style].visible,true);
                 set(hotspot[temp_circle_style].ath,${h});
                 set(hotspot[temp_circle_style].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_polyline_style].visible,true);
                 set(hotspot[temp_polyline_style].point[0].ath,${h});
                 set(hotspot[temp_polyline_style].point[0].atv,${v});`
          )
        }
        if (annotationPosition?.length === 2) {
          krpano.call(
            `set(hotspot[temp_polyline_style].point[1].ath,${h});
                 set(hotspot[temp_polyline_style].point[1].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_circle_middle_style].visible,true);
                 set(hotspot[temp_circle_middle_style].ath,${h});
                 set(hotspot[temp_circle_middle_style].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_polyline_style2].visible,true);
                 set(hotspot[temp_polyline_style2].point[0].ath,${h});
                 set(hotspot[temp_polyline_style2].point[0].atv,${v});`
          )
        }
        if (annotationPosition?.length === 4) {
          krpano.call(
            `set(hotspot[temp_polyline_style2].point[1].ath,${h});
                 set(hotspot[temp_polyline_style2].point[1].atv,${v});`
          )
        }
      }
    }
  }, [annotationPosition?.length, isCheckLockedPoint, isSelectAnnotationPos])

  useEffect(() => {
    getSetAnnotationPosition()
  }, [getSetAnnotationPosition])

  useEffect(() => {
    if (!buildingMode.previewMode || !isCheckLockedPoint) {
      handleCancelPlacing()
    }
  }, [
    buildingMode.previewMode,
    handleCancelPlacing,
    isCheckLockedPoint,
    setSelectedMode
  ])

  useEffect(() => {
    if (!buildingMode.previewMode && isExpanding) {
      setIsExpanding(false)
    }
  }, [buildingMode.previewMode, isExpanding])

  useEffect(() => {
    const historyToggler = document.getElementById('version-history-toggler')
    const pointActionsMenuContainer = document.getElementById(
      'point-action-menu-rightest-element'
    )
    if (!historyToggler || !pointActionsMenuContainer) return

    const togglerBound = historyToggler.getBoundingClientRect()
    const containerBound = pointActionsMenuContainer.getBoundingClientRect()

    if (containerBound.right + 5 >= togglerBound.left) {
      historyToggler.style.top = '98px'
      historyToggler.style.left = '-82px'
    }
    if (containerBound.right + 35 < togglerBound.left) {
      historyToggler.style.top = '20px'
      historyToggler.style.left = '-112px'
    }
  }, [isExpanding])

  if (!buildingMode.previewMode) return null

  return (
    <>
      <div className="flex justify-center items-center gap-[10px] px-4 h-[60px] rounded-[30px] bg-[#021120CC]">
        <div
          className={clsx(
            'w-[50px] flex flex-col items-center justify-between',
            {
              'cursor-pointer': !isDisableCreating
            },
            {
              'cursor-not-allowed opacity-60': isDisableCreating
            }
          )}
          onClick={handleToggleCreatingSetAnnotation}
        >
          <SetAnnoCreateIcon selected={mode === 'addSetAnnotation'} />
          <Typography
            className={`text-center font-bold text-[10px] leading-[10px] ${
              mode === 'addSetAnnotation' ? 'text-[#609DF9]' : 'text-white'
            }`}
          >
            {t('translation.setAnnoCreate')}
          </Typography>
        </div>

        <div
          className={clsx(
            'w-[50px] flex flex-col items-center justify-between',
            {
              'cursor-pointer': !isDisableSearching
            },
            {
              'cursor-not-allowed opacity-60': isDisableSearching
            }
          )}
          onClick={handleToggleModalListSetAnnotation}
        >
          <SetAnnoSearchIcon selected={mode === 'listSetAnnotation'} />
          <Typography
            className={`text-center font-bold text-[10px] leading-[10px] ${
              mode === 'listSetAnnotation' ? 'text-[#609DF9]' : 'text-white'
            }`}
          >
            {t('translation.setAnnoSearch')}
          </Typography>
        </div>

        <div
          className={clsx(
            'w-[50px] flex flex-col items-center justify-between',
            {
              'cursor-pointer': !isDisablePlacing
            },
            {
              'cursor-not-allowed opacity-60': isDisablePlacing
            }
          )}
          onClick={handleTogglePlacingSetAnnotation}
        >
          <SetAnnoPlacingIcon selected={mode === 'placingSetAnnotation'} />
          <Typography
            className={`text-center font-bold text-[10px] leading-[10px] ${
              mode === 'placingSetAnnotation' ? 'text-[#609DF9]' : 'text-white'
            }`}
          >
            {t('translation.setAnnoPlacing')}
          </Typography>
        </div>
      </div>

      {mode === 'addSetAnnotation' && <ModalAddSetAnnotation />}

      {(mode === 'listSetAnnotation' ||
        mode === 'placingSetAnnotation' ||
        mode === 'editSetAnnotation') && (
        <ModalListSetAnnotation
          annotationPosition={annotationPosition}
          setAnnotationPosition={setAnnotationPosition}
        />
      )}

      {mode === 'deleteSetAnnotation' && <ModalDeleteSetAnnotation />}
    </>
  )
}
